import * as constants from 'client/redux/endpoint/constants';
import * as actionCreators from 'client/redux/endpoint/actionCreators';

export const endpointEditPanelRead = id => dispatch => {
  return dispatch(actionCreators.endpointRead(constants.ENDPOINT_PANEL_READ, id));
};

export const endpointEditPanelUpdate = (id, update) => dispatch => {
  return dispatch(actionCreators.endpointUpdate(constants.ENDPOINT_PANEL_UPDATE, id, update));
};

export const endpointEditPanelUpdateSettings = (id, endpointType, update) => dispatch => {
  return dispatch(actionCreators.endpointUpdateSettings(constants.ENDPOINT_PANEL_UPDATE_SETTINGS, id, endpointType, update));
};

export const endpointEditPanelUpdateSystemSettings = (id, endpointType, update) => dispatch => {
  return dispatch(actionCreators.endpointUpdateSystemSettings(constants.ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS, id, endpointType, update));
};
