import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceIndex = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/instance', query),
    method: 'GET',
    types
  });
};

export const instanceCall = (types, id, instanceType, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/${instanceType}/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceRead = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}`,
    method: 'GET',
    types
  });
};

export const instanceReadView = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}/view`,
    method: 'GET',
    types
  });
};

export const instanceDeleteQueueContents = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}/queue/contents`,
    method: 'DELETE',
    json: {},
    types,
  });
};

export const instanceDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}`,
    method: 'DELETE',
    json: {},
    types,
  });
};

export const instanceClone = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}/clone`,
    method: 'POST',
    json: {},
    types: types.map(type => ({type, meta: {}})),
  });
};

export const instanceUpdateAllStatusToActive = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}/allStatusToActive`,
    method: 'POST',
    json: {},
    types: types.map(type => ({type, meta: {}})),
  });
};

export const instanceUpdateStatus = (types, id, newStatus) => {
  return apiAction({
    endpoint: `/api/instance/${id}/status`,
    method: 'POST',
    json: {status: newStatus},
    types: types.map(type => ({type, meta: {newStatus}}))
  });
};

export const instanceUpdateSystemSettings = (types, id, instanceType, json) => {
  return apiAction({
    endpoint: `/api/instance/${instanceType}/${id}/systemSettings`,
    method: 'PUT',
    json,
    types: types.map(type => ({type, meta: {json}}))
  });
};

export const instanceUpdateUserId = (types, id, newUserId) => {
  return apiAction({
    endpoint: `/api/instance/${id}/userId`,
    method: 'POST',
    json: {userId: newUserId},
    types: types.map(type => ({type, meta: {newUserId}}))
  });
};

export const instanceUpdateTitle = (types, id, newTitle) => {
  return apiAction({
    endpoint: `/api/instance/${id}/title`,
    method: 'PUT',
    json: {title: newTitle},
    types: types.map(type => ({type, meta: {newTitle}}))
  });
};

export const instanceReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceReadStatisticsWithKey = (types, id, key, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic, key}}))
  });
};

export const instanceSettingsUpdate = (types, connectorType, id, json) => {
  return apiAction({
    endpoint: `/api/instance/${connectorType}/${id}/settings`,
    method: 'PUT',
    json,
    types
  });
};

export const instanceSettingsUpdateInPlace = (type, instanceId, settings) => ({
  type,
  instanceId,
  settings,
});

export const instanceSetupRead = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/${id}/setup`,
    method: 'GET',
    types
  });
};

export const instanceSetupUpdate = (types, id, form) => {
  return apiAction({
    endpoint: `/api/instance/${id}/setup`,
    method: 'POST',
    json: form,
    types
  });
};

export const instanceOptionalEndpointSetEndpointType = (type, endpointType) => {
  return {type, endpointType};
};

export const instanceOptionalEndpointCreate = (types, instanceId, endpointType) => {
  return apiAction({
    endpoint: `/api/instanceOptionalEndpoint/${instanceId}/${endpointType}`,
    method: 'POST',
    json: {},
    types: types.map(type => ({type, meta: {instanceId, endpointType}})),
  });
};

export const instanceOptionalEndpointDelete = (types, instanceId, endpointType) => {
  return apiAction({
    endpoint: `/api/instanceOptionalEndpoint/${instanceId}/${endpointType}`,
    method: 'DELETE',
    json: {},
    types: types.map(type => ({type, meta: {instanceId, endpointType}})),
  });
};
