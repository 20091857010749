export const INSTANCE_INDEX_REQUEST = 'INSTANCE_INDEX_REQUEST';
export const INSTANCE_INDEX_SUCCESS = 'INSTANCE_INDEX_SUCCESS';
export const INSTANCE_INDEX_FAILURE = 'INSTANCE_INDEX_FAILURE';
export const INSTANCE_INDEX = [INSTANCE_INDEX_REQUEST, INSTANCE_INDEX_SUCCESS, INSTANCE_INDEX_FAILURE];

export const INSTANCE_VIEW_READ_REQUEST = 'INSTANCE_VIEW_READ_REQUEST';
export const INSTANCE_VIEW_READ_SUCCESS = 'INSTANCE_VIEW_READ_SUCCESS';
export const INSTANCE_VIEW_READ_FAILURE = 'INSTANCE_VIEW_READ_FAILURE';
export const INSTANCE_VIEW_READ = [INSTANCE_VIEW_READ_REQUEST, INSTANCE_VIEW_READ_SUCCESS, INSTANCE_VIEW_READ_FAILURE];

export const INSTANCE_OPTIONAL_ENDPOINT_CREATE_REQUEST = 'INSTANCE_OPTIONAL_ENDPOINT_CREATE_REQUEST';
export const INSTANCE_OPTIONAL_ENDPOINT_CREATE_SUCCESS = 'INSTANCE_OPTIONAL_ENDPOINT_CREATE_SUCCESS';
export const INSTANCE_OPTIONAL_ENDPOINT_CREATE_FAILURE = 'INSTANCE_OPTIONAL_ENDPOINT_CREATE_FAILURE';
export const INSTANCE_OPTIONAL_ENDPOINT_CREATE = [INSTANCE_OPTIONAL_ENDPOINT_CREATE_REQUEST, INSTANCE_OPTIONAL_ENDPOINT_CREATE_SUCCESS, INSTANCE_OPTIONAL_ENDPOINT_CREATE_FAILURE];

export const INSTANCE_OPTIONAL_ENDPOINT_DELETE_REQUEST = 'INSTANCE_OPTIONAL_ENDPOINT_DELETE_REQUEST';
export const INSTANCE_OPTIONAL_ENDPOINT_DELETE_SUCCESS = 'INSTANCE_OPTIONAL_ENDPOINT_DELETE_SUCCESS';
export const INSTANCE_OPTIONAL_ENDPOINT_DELETE_FAILURE = 'INSTANCE_OPTIONAL_ENDPOINT_DELETE_FAILURE';
export const INSTANCE_OPTIONAL_ENDPOINT_DELETE = [INSTANCE_OPTIONAL_ENDPOINT_DELETE_REQUEST, INSTANCE_OPTIONAL_ENDPOINT_DELETE_SUCCESS, INSTANCE_OPTIONAL_ENDPOINT_DELETE_FAILURE];

export const INSTANCE_OPTIONAL_ENDPOINT_SET_ENDPOINT_TYPE = 'INSTANCE_OPTIONAL_ENDPOINT_SET_ENDPOINT_TYPE';

export const INSTANCE_PANEL_READ_REQUEST = 'INSTANCE_PANEL_READ_REQUEST';
export const INSTANCE_PANEL_READ_SUCCESS = 'INSTANCE_PANEL_READ_SUCCESS';
export const INSTANCE_PANEL_READ_FAILURE = 'INSTANCE_PANEL_READ_FAILURE';
export const INSTANCE_PANEL_READ = [INSTANCE_PANEL_READ_REQUEST, INSTANCE_PANEL_READ_SUCCESS, INSTANCE_PANEL_READ_FAILURE];

export const INSTANCE_PANEL_CALL_REQUEST = 'INSTANCE_PANEL_CALL_REQUEST';
export const INSTANCE_PANEL_CALL_SUCCESS = 'INSTANCE_PANEL_CALL_SUCCESS';
export const INSTANCE_PANEL_CALL_FAILURE = 'INSTANCE_PANEL_CALL_FAILURE';
export const INSTANCE_PANEL_CALL = [INSTANCE_PANEL_CALL_REQUEST, INSTANCE_PANEL_CALL_SUCCESS, INSTANCE_PANEL_CALL_FAILURE];

export const INSTANCE_PANEL_UPDATE_STATUS_REQUEST = 'INSTANCE_PANEL_UPDATE_STATUS_REQUEST';
export const INSTANCE_PANEL_UPDATE_STATUS_SUCCESS = 'INSTANCE_PANEL_UPDATE_STATUS_SUCCESS';
export const INSTANCE_PANEL_UPDATE_STATUS_FAILURE = 'INSTANCE_PANEL_UPDATE_STATUS_FAILURE';
export const INSTANCE_PANEL_UPDATE_STATUS = [INSTANCE_PANEL_UPDATE_STATUS_REQUEST, INSTANCE_PANEL_UPDATE_STATUS_SUCCESS, INSTANCE_PANEL_UPDATE_STATUS_FAILURE];

export const INSTANCE_PANEL_UPDATE_USER_ID_REQUEST = 'INSTANCE_PANEL_UPDATE_USER_ID_REQUEST';
export const INSTANCE_PANEL_UPDATE_USER_ID_SUCCESS = 'INSTANCE_PANEL_UPDATE_USER_ID_SUCCESS';
export const INSTANCE_PANEL_UPDATE_USER_ID_FAILURE = 'INSTANCE_PANEL_UPDATE_USER_ID_FAILURE';
export const INSTANCE_PANEL_UPDATE_USER_ID = [INSTANCE_PANEL_UPDATE_USER_ID_REQUEST, INSTANCE_PANEL_UPDATE_USER_ID_SUCCESS, INSTANCE_PANEL_UPDATE_USER_ID_FAILURE];

export const INSTANCE_DASHBOARD_READ_REQUEST = 'INSTANCE_DASHBOARD_READ_REQUEST';
export const INSTANCE_DASHBOARD_READ_SUCCESS = 'INSTANCE_DASHBOARD_READ_SUCCESS';
export const INSTANCE_DASHBOARD_READ_FAILURE = 'INSTANCE_DASHBOARD_READ_FAILURE';
export const INSTANCE_DASHBOARD_READ = [INSTANCE_DASHBOARD_READ_REQUEST, INSTANCE_DASHBOARD_READ_SUCCESS, INSTANCE_DASHBOARD_READ_FAILURE];

export const INSTANCE_DASHBOARD_DELETE_REQUEST = 'INSTANCE_DASHBOARD_DELETE_REQUEST';
export const INSTANCE_DASHBOARD_DELETE_SUCCESS = 'INSTANCE_DASHBOARD_DELETE_SUCCESS';
export const INSTANCE_DASHBOARD_DELETE_FAILURE = 'INSTANCE_DASHBOARD_DELETE_FAILURE';
export const INSTANCE_DASHBOARD_DELETE = [INSTANCE_DASHBOARD_DELETE_REQUEST, INSTANCE_DASHBOARD_DELETE_SUCCESS, INSTANCE_DASHBOARD_DELETE_FAILURE];

export const INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_REQUEST = 'INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_REQUEST';
export const INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_SUCCESS = 'INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_SUCCESS';
export const INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_FAILURE = 'INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_FAILURE';
export const INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS = [INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_REQUEST, INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_SUCCESS, INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_FAILURE];

export const INSTANCE_DASHBOARD_UPDATE_STATUS_REQUEST = 'INSTANCE_DASHBOARD_UPDATE_STATUS_REQUEST';
export const INSTANCE_DASHBOARD_UPDATE_STATUS_SUCCESS = 'INSTANCE_DASHBOARD_UPDATE_STATUS_SUCCESS';
export const INSTANCE_DASHBOARD_UPDATE_STATUS_FAILURE = 'INSTANCE_DASHBOARD_UPDATE_STATUS_FAILURE';
export const INSTANCE_DASHBOARD_UPDATE_STATUS = [INSTANCE_DASHBOARD_UPDATE_STATUS_REQUEST, INSTANCE_DASHBOARD_UPDATE_STATUS_SUCCESS, INSTANCE_DASHBOARD_UPDATE_STATUS_FAILURE];

export const INSTANCE_DELETE_REQUEST = 'INSTANCE_DELETE_REQUEST';
export const INSTANCE_DELETE_SUCCESS = 'INSTANCE_DELETE_SUCCESS';
export const INSTANCE_DELETE_FAILURE = 'INSTANCE_DELETE_FAILURE';
export const INSTANCE_DELETE = [INSTANCE_DELETE_REQUEST, INSTANCE_DELETE_SUCCESS, INSTANCE_DELETE_FAILURE];

export const INSTANCE_CLONE_REQUEST = 'INSTANCE_CLONE_REQUEST';
export const INSTANCE_CLONE_SUCCESS = 'INSTANCE_CLONE_SUCCESS';
export const INSTANCE_CLONE_FAILURE = 'INSTANCE_CLONE_FAILURE';
export const INSTANCE_CLONE = [INSTANCE_CLONE_REQUEST, INSTANCE_CLONE_SUCCESS, INSTANCE_CLONE_FAILURE];

export const INSTANCE_UPDATE_TITLE_REQUEST = 'INSTANCE_UPDATE_TITLE_REQUEST';
export const INSTANCE_UPDATE_TITLE_SUCCESS = 'INSTANCE_UPDATE_TITLE_SUCCESS';
export const INSTANCE_UPDATE_TITLE_FAILURE = 'INSTANCE_UPDATE_TITLE_FAILURE';
export const INSTANCE_UPDATE_TITLE = [INSTANCE_UPDATE_TITLE_REQUEST, INSTANCE_UPDATE_TITLE_SUCCESS, INSTANCE_UPDATE_TITLE_FAILURE];

export const INSTANCE_SETTINGS_UPDATE_REQUEST = 'INSTANCE_SETTINGS_UPDATE_REQUEST';
export const INSTANCE_SETTINGS_UPDATE_SUCCESS = 'INSTANCE_SETTINGS_UPDATE_SUCCESS';
export const INSTANCE_SETTINGS_UPDATE_FAILURE = 'INSTANCE_SETTINGS_UPDATE_FAILURE';
export const INSTANCE_SETTINGS_UPDATE = [INSTANCE_SETTINGS_UPDATE_REQUEST, INSTANCE_SETTINGS_UPDATE_SUCCESS, INSTANCE_SETTINGS_UPDATE_FAILURE];

export const INSTANCE_SETTINGS_UPDATE_IN_PLACE = 'INSTANCE_SETTINGS_UPDATE_IN_PLACE';

export const INSTANCE_SETUP_READ_REQUEST = 'INSTANCE_SETUP_READ_REQUEST';
export const INSTANCE_SETUP_READ_SUCCESS = 'INSTANCE_SETUP_READ_SUCCESS';
export const INSTANCE_SETUP_READ_FAILURE = 'INSTANCE_SETUP_READ_FAILURE';
export const INSTANCE_SETUP_READ = [INSTANCE_SETUP_READ_REQUEST, INSTANCE_SETUP_READ_SUCCESS, INSTANCE_SETUP_READ_FAILURE];

export const INSTANCE_SETUP_UPDATE_REQUEST = 'INSTANCE_SETUP_UPDATE_REQUEST';
export const INSTANCE_SETUP_UPDATE_SUCCESS = 'INSTANCE_SETUP_UPDATE_SUCCESS';
export const INSTANCE_SETUP_UPDATE_FAILURE = 'INSTANCE_SETUP_UPDATE_FAILURE';
export const INSTANCE_SETUP_UPDATE = [INSTANCE_SETUP_UPDATE_REQUEST, INSTANCE_SETUP_UPDATE_SUCCESS, INSTANCE_SETUP_UPDATE_FAILURE];

export const INSTANCE_SETUP_UPDATE_STATUS_REQUEST = 'INSTANCE_SETUP_UPDATE_STATUS_REQUEST';
export const INSTANCE_SETUP_UPDATE_STATUS_SUCCESS = 'INSTANCE_SETUP_UPDATE_STATUS_SUCCESS';
export const INSTANCE_SETUP_UPDATE_STATUS_FAILURE = 'INSTANCE_SETUP_UPDATE_STATUS_FAILURE';
export const INSTANCE_SETUP_UPDATE_STATUS = [INSTANCE_SETUP_UPDATE_STATUS_REQUEST, INSTANCE_SETUP_UPDATE_STATUS_SUCCESS, INSTANCE_SETUP_UPDATE_STATUS_FAILURE];

export const INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_REQUEST = 'INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_REQUEST';
export const INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_SUCCESS = 'INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_SUCCESS';
export const INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_FAILURE = 'INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_FAILURE';
export const INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE = [INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_REQUEST, INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_SUCCESS, INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE_FAILURE];

export const INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST = 'INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST';
export const INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS = 'INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS';
export const INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE = 'INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE';
export const INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS = [INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST, INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS, INSTANCE_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE];

export const INSTANCE_INDEX_SET_COLUMNS = 'INSTANCE_INDEX_SET_COLUMNS';

export const statusSelect2 = [
  {id: 'setup', text: 'Setup'},
  {id: 'active', text: 'Active'},
  {id: 'inactive', text: 'Inactive'},
  {id: 'paused', text: 'Paused'},
  {id: 'error', text: 'Error'}
];
