import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Field } from 'redux-form';
import BeLoading from 'client/components/Be/BeLoading';
import InstanceFieldUserId from 'client/components/Instance/components/InstanceFieldUserId';
import FormFieldStatus from 'client/components/Form/FormFieldStatus';
import FormContainer from 'client/components/Form/FormContainer';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { statusSelect2 } from 'client/redux/instance/constants';
import * as panelActions from 'client/redux/instance/panelActions';
import { ButtonWaitingPromise, ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import AITDPanel from 'client/components/AITD/AITDPanel';
import GRTDPanel from 'client/components/GRTD/GRTDPanel';
import FATDPanel from 'client/components/FATD/FATDPanel';
import FATDIPanel from 'client/components/FATDI/FATDIPanel';
import FSTDPanel from 'client/components/FSTD/FSTDPanel';
import OPTDPanel from 'client/components/OPTD/OPTDPanel';
import MWTDPanel from 'client/components/MWTD/MWTDPanel';
import SWTDPanel from 'client/components/SWTD/SWTDPanel';

class InstancePanelModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    instance: propTypes.object,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired
  }

  state = {
    wasUpdated: false,
  }

  getInstanceProps() {
    const { instance } = this.props;
    if(!instance) return {};
    const { id, type } = instance;
    return { id, type };
  }

  handleClose = () => {
    const { wasUpdated } = this.state;
    return this.props.onClose({wasUpdated});
  }

  handleUpdated = result => {
    this.setState({wasUpdated: true});
  }

  handleCall = method => {
    const { id, type } = this.getInstanceProps();
    return this.props.instancePanelCall(id, type, method).then(this.handleUpdated);
  }

  handleUpdateUserId = ({userId}) => {
    const { id } = this.getInstanceProps();
    return this.props.instancePanelUpdateUserId(id, userId).then(this.handleUpdated);
  }

  handleUpdateStatus = ({status}) => {
    const { id } = this.getInstanceProps();
    return this.props.instancePanelUpdateStatus(id, status).then(this.handleUpdated);
  }

  handleUpdateSystemSettings = (form) => {
    const { id, type } = this.getInstanceProps();
    return this.props.instancePanelUpdateSystemSettings(id, type, form).then(this.handleUpdated);
  }

  handleSetInstanceAndEndpointsToActive = () => {
    const { id } = this.getInstanceProps();
    return this.props.instancePanelUpdateAllStatusToActive(id).then(this.handleUpdated);
  }

  renderLowerBody() {
    const { type } = this.getInstanceProps();
    switch(type) {
      default: return null;
      case 'aitd': return <AITDPanel onCall={this.handleCall} />;
      case 'grtd': return <GRTDPanel onCall={this.handleCall} />;
      case 'fstd': return <FSTDPanel onCall={this.handleCall} />;
      case 'fatd': return <FATDPanel onCall={this.handleCall} />;
      case 'fatdi': return <FATDIPanel onCall={this.handleCall} />;
      case 'optd': return <OPTDPanel onCall={this.handleCall} />;
      case 'swtd': return <SWTDPanel onCall={this.handleCall} />;
      case 'mwtd': return <MWTDPanel onCall={this.handleCall} />;
    }
  }

  renderSystemSettingsForm () {
    const { instance } = this.props;
    const { type, systemSettings = {} } = instance;
    if (type !== 'aitd') return null;
    return (
      <FormContainer
        onSubmit={this.handleUpdateSystemSettings}
        form="instanceFormSystemSettings"
        initialValues={{aiSearchApiKey: systemSettings.aiSearchApiKey || ''}}
      >
        {props => (
          <div className="well">
            <h4 className="xs-mt-5">System settings</h4>
            <Field
              base="instanceFormSystemSettings"
              component={FormReduxInput}
              name="aiSearchApiKey"
              label="Azure AI Search API key"
              required
            />
            <ButtonWaiting
              type="submit"
              className="btn btn-primary"
              isWaiting={props.submitting}
              disabled={props.invalid || props.pristine}
            >
              Save
            </ButtonWaiting>
          </div>
        )}
      </FormContainer>
    );
  }

  render() {
    const { open, instance, isReading, error } = this.props;
    const connectorTitle = _.get(instance, 'Connector.title', instance.connectorId);
    return (
      <Modal id="instancePanelModal" dialogClassName="colored-header colored-header-primary" show={open} onHide={this.handleClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={this.handleClose} />
          <Modal.Title>{connectorTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BeRemoteErrorAlert error={error} />
          <BeLoading loading={isReading}>
            <FormContainer onSubmit={this.handleUpdateUserId} form="instanceFormUserId">
              {props => (
                <Field
                base="instanceFormUserId"
                dropdownParent="#instancePanelModal"
                name="userId"
                component={InstanceFieldUserId}
                defaultValue={instance.userId}
                />
              )}
            </FormContainer>
            <FormContainer onSubmit={this.handleUpdateStatus} form="instanceFormStatus">
              {props => (
                <Field
                  base="instanceFormStatus"
                  dropdownParent="#instancePanelModal"
                  name="status"
                  component={FormFieldStatus}
                  data={statusSelect2}
                  defaultValue={instance.status}
                />
              )}
            </FormContainer>
            {this.renderSystemSettingsForm()}
            {this.renderLowerBody()}
          </BeLoading>
        </Modal.Body>
        <Modal.Footer className="text-right" style={{borderTop: '1px solid #e5e5e5'}}>
          <ButtonWaitingPromise
            className="btn btn-primary"
            onClick={this.handleSetInstanceAndEndpointsToActive}
          >
            Set instance and endpoints to active
          </ButtonWaitingPromise>
        </Modal.Footer>
      </Modal>
    );
  }
}

const actions = {
  ...panelActions,
};

const mapStateToProps = state => ({
  ...state.instance.panel,
});

export default connect(mapStateToProps, actions)(InstancePanelModal);
