import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const endpointPreloaded = (type, endpoint) => {
  return {
    type,
    endpoint
  };
};

export const endpointIndex = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/endpoint', query),
    method: 'GET',
    types
  });
};

export const endpointRead = (types, id, endpointType) => {
  return apiAction({
    endpoint: appendQuery(`/api/endpoint/${id}`, {type: endpointType}),
    method: 'GET',
    types
  });
};

export const endpointUpdate = (types, id, update) => {
  return apiAction({
    endpoint: `/api/endpoint/${id}`,
    method: 'PATCH',
    json: update,
    types
  });
};

export const endpointUpdateSettings = (types, id, endpointType, update) => {
  return apiAction({
    endpoint: `/api/endpoint/${endpointType}/${id}/settings`,
    method: 'PATCH',
    json: update,
    types
  });
};

export const endpointUpdateSystemSettings = (types, id, endpointType, update) => {
  return apiAction({
    endpoint: `/api/endpoint/${endpointType}/${id}/systemSettings`,
    method: 'PUT',
    json: update,
    types
  });
};

export const endpointDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/${id}`,
    method: 'DELETE',
    types
  });
};
