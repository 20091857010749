export const ENDPOINT_INDEX_REQUEST = 'ENDPOINT_INDEX_REQUEST';
export const ENDPOINT_INDEX_SUCCESS = 'ENDPOINT_INDEX_SUCCESS';
export const ENDPOINT_INDEX_FAILURE = 'ENDPOINT_INDEX_FAILURE';
export const ENDPOINT_INDEX = [ENDPOINT_INDEX_REQUEST, ENDPOINT_INDEX_SUCCESS, ENDPOINT_INDEX_FAILURE];

export const ENDPOINT_INDEX_DELETE_REQUEST = 'ENDPOINT_INDEX_DELETE_REQUEST';
export const ENDPOINT_INDEX_DELETE_SUCCESS = 'ENDPOINT_INDEX_DELETE_SUCCESS';
export const ENDPOINT_INDEX_DELETE_FAILURE = 'ENDPOINT_INDEX_DELETE_FAILURE';
export const ENDPOINT_INDEX_DELETE = [ENDPOINT_INDEX_DELETE_REQUEST, ENDPOINT_INDEX_DELETE_SUCCESS, ENDPOINT_INDEX_DELETE_FAILURE];

export const ENDPOINT_INDEX_ORDERBY = 'ENDPOINT_INDEX_ORDERBY';

export const ENDPOINT_INDEX_SET_PAGE = 'ENDPOINT_INDEX_SET_PAGE';

export const ENDPOINT_INDEX_FILTER = 'ENDPOINT_INDEX_FILTER';

export const ENDPOINT_PANEL_READ_REQUEST = 'ENDPOINT_PANEL_READ_REQUEST';
export const ENDPOINT_PANEL_READ_SUCCESS = 'ENDPOINT_PANEL_READ_SUCCESS';
export const ENDPOINT_PANEL_READ_FAILURE = 'ENDPOINT_PANEL_READ_FAILURE';
export const ENDPOINT_PANEL_READ = [ENDPOINT_PANEL_READ_REQUEST, ENDPOINT_PANEL_READ_SUCCESS, ENDPOINT_PANEL_READ_FAILURE];

export const ENDPOINT_PANEL_UPDATE_REQUEST = 'ENDPOINT_PANEL_UPDATE_REQUEST';
export const ENDPOINT_PANEL_UPDATE_SUCCESS = 'ENDPOINT_PANEL_UPDATE_SUCCESS';
export const ENDPOINT_PANEL_UPDATE_FAILURE = 'ENDPOINT_PANEL_UPDATE_FAILURE';
export const ENDPOINT_PANEL_UPDATE = [ENDPOINT_PANEL_UPDATE_REQUEST, ENDPOINT_PANEL_UPDATE_SUCCESS, ENDPOINT_PANEL_UPDATE_FAILURE];

export const ENDPOINT_PANEL_UPDATE_SETTINGS_REQUEST = 'ENDPOINT_PANEL_UPDATE_SETTINGS_REQUEST';
export const ENDPOINT_PANEL_UPDATE_SETTINGS_SUCCESS = 'ENDPOINT_PANEL_UPDATE_SETTINGS_SUCCESS';
export const ENDPOINT_PANEL_UPDATE_SETTINGS_FAILURE = 'ENDPOINT_PANEL_UPDATE_SETTINGS_FAILURE';
export const ENDPOINT_PANEL_UPDATE_SETTINGS = [ENDPOINT_PANEL_UPDATE_SETTINGS_REQUEST, ENDPOINT_PANEL_UPDATE_SETTINGS_SUCCESS, ENDPOINT_PANEL_UPDATE_SETTINGS_FAILURE];

export const ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST = 'ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST';
export const ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS = 'ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS';
export const ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE = 'ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE';
export const ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS = [ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_REQUEST, ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_SUCCESS, ENDPOINT_PANEL_UPDATE_SYSTEM_SETTINGS_FAILURE];

export const statusSelect2 = [
  {id: 'setup', text: 'Setup'},
  {id: 'error', text: 'Error'},
  {id: 'ready', text: 'Ready'}
];

export const graphUseBetaVersionSelect2 = [
  {id: false, text: 'Use v1.0'},
  {id: true, text: 'Use beta'},
];
