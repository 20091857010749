import React, {useState} from 'react';
import LogLevelLabel from 'client/components/Helpers/LogLevelLabel';
import InstanceLogDescription from 'client/components/InstanceLog/components/InstanceLogDescription'
import Icon from 'client/components/Be/Icon';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import LinkQuery from 'client/components/Helpers/LinkQuery';
import Time from 'client/components/Helpers/Time';
import BeLoading from 'client/components/Be/BeLoading';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import { Pagination } from '@react-bootstrap/pagination';
import { Table } from 'react-bootstrap';

export default function InstanceEventTable (props) {
  const { instanceId } = props;

  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: [`/api/instance/${instanceId}/logs`, {page, limit: 30}],
    placeholderData: keepPreviousData,
  });

  const onSelectPage = page => {
    setPage(page);
  };

  const logs = query.data?.logs ?? [];

  return (
    <div className="table-responsive">
      <BeLoading loading={query.isFetching}>
        <Table striped className="xs-mb-0" hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th>Description</th>
              <th className="actions"></th>
            </tr>
          </thead>
          <tbody className="no-border-x">
            {logs.length > 0 ? (
              <>
                {logs.map(log => (
                  <tr key={log.id}>
                    <td className="text-nowrap">
                      <LogLevelLabel level={log.level} />
                    </td>
                    <td className="text-nowrap">
                      <Time time={log.time} format="YYYY-MM-DD HH:mm" />
                    </td>
                    <td className="text-nowrap">
                      <InstanceLogDescription {...log} maxLineLength={100} />
                    </td>
                    <td className="text-nowrap text-right">
                      {log.level === 'job' && (
                        <IsAdmin>
                          <LinkQuery
                            className="btn btn-default xs-mr-5"
                            to="/instance/log/fragment"
                            query={{connectorInstanceLogId: log.id}}
                            title="Search for log fragments"
                          >
                            <Icon icon="search-for" />
                          </LinkQuery>
                        </IsAdmin>
                      )}
                      <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: log, title: log.time, inspector: 'object'}}>
                        <i className="icon mdi mdi-search"></i>
                      </ModalButton>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="4">
                  There is nothing here now
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </BeLoading>
      {query.isSuccess && (
        <div className="panel-footer">
          <Pagination
            className="xs-mb-5 xs-mt-0"
            first={true}
            last={true}
            next={'Next'}
            prev={'Prev'}
            bsSize="medium"
            maxButtons={6}
            activePage={page}
            items={query.data.pages ?? 1}
            onSelect={onSelectPage}
          />
        </div>
      )}
    </div>
  );
}
