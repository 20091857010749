import React from 'react';
import classNames from 'classnames';
import { withRouter, matchPath } from 'react-router';
import { connect } from 'react-redux';
import AppHasLoaded from 'client/components/App/AppHasLoaded';
import { StripeContextLoader } from 'client/components/App/StripeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'client/axios';
import 'client/components/App/App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0,
      gcTime: 0,
      refetchOnWindowFocus: false,
      queryFn: async (context) => {
        const {queryKey, signal, meta} = context;
        if (typeof queryKey[0] !== 'string') return null;
        const [url, params] = queryKey;
        const response = await axios.get(url, {params, signal});

        if (typeof meta?.responseExtractor === 'function') {
          return meta.responseExtractor(response);
        }
        return response.data;
      },
    },
  },
});

class App extends React.Component {

  getColorClassName() {
    const { color } = this.props;
    switch(color) {
      default: return '';
      case 'primary': return ['be-color-header'];
      case 'success': return ['be-color-header', 'be-color-header-success'];
      case 'danger': return ['be-color-header', 'be-color-header-danger'];
      case 'warning': return ['be-color-header', 'be-color-header-warning'];
    }
  }

  getClassName() {
    const { className, location } = this.props;
    const classes = ['ln-app', 'be-wrapper', this.getColorClassName(), className];
    if(matchPath(location.pathname, {path: '/support', exact: true})) {
      classes.push('be-aside');
    }
    return classNames(classes);
  }

  render() {
    const { children } = this.props;
    return (
      <QueryClientProvider client={queryClient}>
        <AppHasLoaded>
          <StripeContextLoader>
            <div className={this.getClassName()}>
              {children}
            </div>
          </StripeContextLoader>
        </AppHasLoaded>
      </QueryClientProvider>
    );
  }

}

export default withRouter(connect(state => state.navbar.color)(App));
