export const INSTANCE_LOG_INDEX_REQUEST = 'INSTANCE_LOG_INDEX_REQUEST';
export const INSTANCE_LOG_INDEX_SUCCESS = 'INSTANCE_LOG_INDEX_SUCCESS';
export const INSTANCE_LOG_INDEX_FAILURE = 'INSTANCE_LOG_INDEX_FAILURE';
export const INSTANCE_LOG_INDEX = [INSTANCE_LOG_INDEX_REQUEST, INSTANCE_LOG_INDEX_SUCCESS, INSTANCE_LOG_INDEX_FAILURE];

export const INSTANCE_LOG_INDEX_ORDERBY = 'INSTANCE_LOG_INDEX_ORDERBY';

export const INSTANCE_LOG_INDEX_SET_PAGE = 'INSTANCE_LOG_INDEX_SET_PAGE';

export const INSTANCE_LOG_INDEX_FILTER = 'INSTANCE_LOG_INDEX_FILTER';

export const levelSelect2 = [
  {id: 'error', text: 'Error'},
  {id: 'warning', text: 'Warning'},
  {id: 'info', text: 'Info'},
  {id: 'success', text: 'Success'},
  {id: 'job', text: 'Job'},
];

export const countKeyToNatural = key => {
  switch(key) {
    default: return key;
    case 'NOTICES_COUNT': return 'Notices';
    case 'ERRORS_COUNT': return 'Errors';
    case 'CREDITS_USED': return 'Credits';
    case 'CALLS_HANDLED': return 'Calls handled';
    case 'duration': return 'Duration';
    case 'taskCount': return 'Tasks added to queue';
  }
};

export const noticeObjectToNatural = object => {
  switch(object) {
    default: return object;

    case 'FILE': return 'File';
    case 'FILES_OBJECT': return 'File Row';

    case 'FAST_PARTNER': return 'FastAPI Partner';
    case 'FAST_CASE': return 'FastAPI Case';
    case 'FAST_ERRAND': return 'FastAPI Errand';
    case 'FAST_CASE_AND_ORDER': return 'FastAPI Case+Order';
    case 'FAST_ERRAND_AND_ORDER': return 'FastAPI Errand+Order';
    case 'FAST_OBJECT': return 'FastAPI Object';
    case 'FAST_ORDER': return 'FastAPI Order';

    case 'GRAPH_USER': return 'Graph User';
    case 'OPMANAGER_DEVICE': return 'OpManager Device';
    case 'OPMANAGER_ALARM': return 'OpManager Alarm';
    case 'MANAGEDWORKPLACE_DEVICE': return 'Managed Workplace Device';
    case 'MANAGEDWORKPLACE_TROUBLETICKET': return 'Managed Workplace Trouble Ticket';
    case 'TOPDESK_INCIDENT': return 'TOPdesk Incident';
    case 'TOPDESK_INCIDENT_ACTIONS': return 'TOPdesk Incident Actions';
    case 'TOPDESK_INCIDENT_REQUESTS': return 'TOPdesk Incident Requests';
    case 'TOPDESK_OPERATOR': return 'TOPdesk Operator';
    case 'TOPDESK_PERSON': return 'TOPdesk Person';
    case 'TOPDESK_DEPARTMENT': return 'TOPdesk Department';
    case 'TOPDESK_BUDGETHOLDER': return 'TOPdesk Budget Holder';
    case 'TOPDESK_BRANCH': return 'TOPdesk Branch';
    case 'TOPDESK_ASSET_TEMPLATE': return 'TOPdesk Asset Template';
    case 'TOPDESK_ASSET': return 'TOPdesk Asset';
  }
};

export const noticeActionToNatural = action => {
  switch(action) {
    case 'ASSIGNED': return 'Assigned';
    case 'UNASSIGNED': return 'Unassigned';
    case 'CREATED': return 'Created';
    case 'UPDATED': return 'Updated';
    case 'ARCHIVED':  return 'Archived';
    case 'UNARCHIVED': return 'Unarchived';
    case 'PAIRED_WITH_OPERATOR': return 'Paired with TOPdesk Operator';
    case 'PAIRED_WITH_PERSON': return 'Paired with TOPdesk Person';
    case 'UNPAIRED': return 'Unpaired';
    case 'SKIPPED': return 'Skipped';
    case 'NOOP': return 'Ignored';
    case 'FOUND': return 'Found';
    case 'LINKED': return 'Linked';
    case 'UNLINKED': return 'Unlinked';
    case 'RECEIVED': return 'Received';
    default: return action;
  }
};

export const pairingDescription = obj => {
  const withPerson = 'topdeskPersonId' in obj;
  const withOperator = 'topdeskOperatorId' in obj;
  if(withPerson && withOperator) return 'TOPdesk Operator and a TOPdesk Person';
  if(withPerson) return 'TOPdesk Person';
  if(withOperator) return 'TOPdesk Operator';
  return 'noone';
};
